import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import styles from './Header.module.scss';

const Header = ({ siteTitle }) => (
  <header>
    <div className={styles.header}>
      <h1 className={styles.name}>
        <Link to="/">
          {siteTitle}
        </Link>
      </h1>
      <h2 className={styles.title}>software &amp; design</h2>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
