import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { CSSTransition } from "react-transition-group"
import { useStaticQuery, graphql } from "gatsby"

import "./main.scss"

import Header from "./Header/Header"
import Footer from "./Footer/Footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [pageLoaded, setPageLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(true)
    }, 200)
  }, [])

  return (
    <CSSTransition
      in={pageLoaded}
      timeout={500}
      classNames="page"
      unmountOnExit
    >
      <div className="root">
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
        <Footer />
      </div>
    </CSSTransition>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
