import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLinkedinIn,
  faGithub,
  faStackOverflow,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"

import styles from "./Footer.module.scss"

const Footer = () => (
  <footer>
    <div className={styles.footer}>
      <p>&copy; {new Date().getFullYear()}</p>
      <div className={styles.icons}>
        <a
          href="https://github.com/lstanard"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faGithub} />
        </a>
        <a
          href="https://stackoverflow.com/users/1183876/charlie-stanard"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faStackOverflow} />
        </a>
        <a
          href="https://www.linkedin.com/in/charlie-louis-stanard-1b051ab"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedinIn} />
        </a>
        <a
          href="https://twitter.com/louisstanard"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      </div>
    </div>
  </footer>
)

export default Footer
